<template>
  <div class="main">
    <div class="title">系统管理/角色管理</div>
    <!-- 列表信息 -->
    <div class="listBox">
      <div class="header-flex">
        <div class="info">角色信息列表</div>
        <el-button
          style="background: rgb(29, 68, 128); color: #fff"
          @click="handleAlarm(0, 1)"
          >新增角色</el-button
        >
      </div>

      <!-- 列表信息 -->
      <div class="list">
        <el-table
          ref="multipleTable"
          border
          :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; color: rgba(0, 0, 0, 0.85)"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" align='center' width="55">
                    </el-table-column> -->
          <el-table-column type="index" align="center" label="序号" width="55">
          </el-table-column>
          <el-table-column
            prop="roleName"
            align="center"
            label="角色名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="description"
            align="center"
            label="角色描述"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="gmtCreate"
            align="center"
            label="添加日期"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="scope">
              <div style="color: #1a4382; cursor: pointer">
                <span @click="handleAlarm(1, scope.row)">编辑</span>
                <!-- <span
                  style="margin-left: 5px"
                  @click="deleteClick(scope.row.id)"
                  >删除</span
                > -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 50, 100, 200, 300, 400]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 角色管理弹框 -->
    <el-dialog :title="roleTile" :visible.sync="AlarmVisible" width="700px">
      <el-form
        :model="form"
        :rules="rules"
        label-width="100px"
        ref="ruleForm"
        style="padding: 0 100px"
      >
        <el-form-item label="角色名称：" prop="roleName">
          <!-- <el-col :span="18"> -->
          <el-input
            style="width: 320px"
            placeholder="请输入角色名称"
            v-model="form.roleName"
          ></el-input>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item label="角色描述：" prop="roleDesc">
          <!-- <el-col :span="18"> -->
          <el-input
            type="textarea"
            style="width: 320px"
            placeholder="请输入角色描述"
            v-model="form.roleDesc"
          ></el-input>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item label="配置权限：" prop="selectId">
          <el-tree
          style="width: 320px"
          :data="data"
          show-checkbox
          node-key="id"
          ref="tree"
          default-expand-all
          :default-checked-keys="form.selectId"
          :props="defaultProps"
          check-strictly
        >
        </el-tree>
        </el-form-item>        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleFrom('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    let validOrgan = (rule, value, callback) => {
        // 获取选中节点数组
        let arr = this.$refs.tree.getCheckedKeys();
        if(!arr || arr.length === 0) {
            callback(new Error('请选择列表权限'))
        } else {
            callback();
        }
    }
    return {
      roleTile: "",
      roleType: "",
      // 角色id
      roleId: "",
      // 权限配置信息
      menulist: [],
      // 权限配置
      data: [
        {
          id: 1,
          label: "设备信息",
        },
        {
          id: 2,
          label: "开关控制",
        },
        {
          id: 3,
          label: "报警管理",
        },
        {
          id: 4,
          label: "系统设置",
          children: [
            {
              id: 5,
              label: "角色管理",
            },
            {
              id: 6,
              label: "账户管理",
            },
            {
              id: 7,
              label: "修改密码",
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: function (data) {
          return data.permissionName;
        },
      },
      // 列表信息
      tableData: [],
      // 选择列表id
      multipleSelection: [],
      // 分页
      currentPage: 1,
      pageSize: 10,
      totalPage: 10,
      // 报警弹框信息
      AlarmVisible: false,
      form: {
        roleName: "",
        roleDesc: "",
        selectId:[]
      },
      // 弹框规则
      rules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        // roleDesc: [
        //   { required: true, message: "请输入角色描述", trigger: "blur" },
        // ],
        selectId: [
        { required: true, validator: validOrgan, trigger: 'change' },
        ],
      },
    };
  },
  mounted() {
    this.getRoleData();
    this.getMenuList();
  },
  methods: {
    // 获取角色列表接口
    getRoleData() {
      let param = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$post("/user/role/findRoleList", param)
        .then((res) => {
          if (res.code == 1) {
            console.log(res);
            this.tableData = res.data.list;
            this.tableData.splice(0,1)
            this.totalPage = res.data.total-1;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 获取权限菜单管理
    getMenuList() {
      this.$get("/user/role/menuTreeList")
        .then((res) => {
          if (res.code == 1) {
            
            this.data = res.data;
            this.data[0].children.splice(0,1)
            console.log(this.data)
            this.form.selectId = [];
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
      });

      console.log(this.multipleSelection);
    },
    //
    // 编辑/新增
    handleAlarm(type, row) {
      this.roleType = type;
      if (type == 0) {
        this.roleTile = "新增角色";
        this.form.roleName = "";
        this.roleId = '';
        this.form.roleDesc = ''
        this.form.selectId = [];
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.form.selectId);
        });
      } else {
        this.roleTile = "编辑角色";
        this.roleId = row.id;
        this.getRoleIdMenuList();
        this.form.roleName = row.roleName;
        this.form.roleDesc = row.description
      }
      this.AlarmVisible = true;
    },
    // 获取权限菜单id
    readNodes(nodes, arr) {
      for (let item of nodes) {
        arr.push(item.id);
        if (item.children && item.children.length)
          this.readNodes(item.children, arr);
      }
      return arr;
    },
    // 根据角色id获取权限菜单
    getRoleIdMenuList() {
      let param = {
        rid: this.roleId,
      };
      this.$get("/user/role/menuTreeListByRid", param)
        .then((res) => {
          if (res.code == 1) {
            console.log(res.data);
            this.$nextTick(() => {
              let arr = [];
              this.form.selectId = this.readNodes(res.data, arr);
              console.log(this.form.selectId);
              // 实时改变默认选中值
              this.$nextTick(() => {
                this.$refs.tree.setCheckedKeys(this.form.selectId);
              });
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 添加修改角色
    addEditRole() {
      let param = {
        id: this.roleId,
        roleName: this.form.roleName,
        description:this.form.roleDesc,
        permissionList: this.form.selectId,
      };
      console.log(param);
      this.$post("/user/role/saveOrUpdateRole", param)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 删除按钮
    deleteClick(id) {
      var that = this;
      console.log(id);
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warnimg",
      })
        .then((status) => {
          if (status == "confirm") {
            // this.logout();
            let param = {
              id: id,
            };
            this.$get("/user/role/del", param)
              .then((res) => {
                if (res.code == 1) {
                  this.$message.success(res.msg);
                  setTimeout(() => {
                    that.getRoleData();
                  }, 100);
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch(() => {
                console.log("异常");
              });
          }
        })
        .catch((status) => {
          if (status == "cancel") {
            // this.cancelLogout();
          }
        });
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getRoleData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getRoleData();
    },
    // 提交表单
    submitForm(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const checkedkeys = this.$refs.tree.getHalfCheckedKeys();
          //子节点的权限id
          const id = this.$refs.tree.getCheckedKeys();
          this.form.selectId = checkedkeys.concat(id);
          console.log(this.form.selectId);
          this.addEditRole();
          setTimeout(() => {
            that.getRoleData();
          }, 100);
          this.AlarmVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //   取消
    cancleFrom(formName) {
      this.$refs[formName].resetFields();
      this.AlarmVisible = false;
    },
  },
};
</script>
<style>
.el-form-item__content {
  width: 500px;
}
</style>
<style lang="scss" scoped>
.main {
  margin: 10px;
  color: rgba(0, 0, 0, 0.85);
  font-family: PingFang SC;
  font-size: 16px;
  .listBox {
    margin: 10px 0;
    background: #fff;
    padding: 15px 20px;
    .header-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .list {
      margin: 15px auto;
    }
    // 处理报警操作
    .handleoper {
      position: relative;
      cursor: pointer;
      .redCircle {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: red;
        border-radius: 50%;
        position: absolute;
        top: 0;
      }
    }

    // 分页
    .page {
      width: 100%;
      text-align: right;
    }
  }
}
</style>
